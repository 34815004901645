import request from "@/utils/request";

export function getUsers(page = 1){
  return request(
    {
      url: `user?page=${page}`
    }
  )
}

export function getUserTypes() {
  return request(
    {
      url: "user/types",
      method: "get"
    }
  )
}

export function addNewUser(data) {
  return request(
    {
      url: "user/register",
      method: "post",
      data
    }
  )
}

export function getUser(user_id) {
  return request(
    {
      url: `user/${user_id}`
    }
  )
}

export function updateUser(user_id,data) {
  return request(
    {
      url: `user/update/${user_id}`,
      method: 'put',
      data
    }
  )
}


export function disableUser(user_id){
  return request (
    {
      url: `user/disable/${user_id}`,
      method: 'get'
    }
  )
}
