<template>
  <div class="form-group">
    <label :for="inputKey">{{ labelText }}</label>

    <Field
        :name="inputKey"
        :id="inputKey"
        :value="prev_val"
        @input="e=>$emit('update',this.inputKey,e.target.value)"
        :placeholder="labelText"
        class="form-control form-control-solid"
        :rules="!notRequired ? isRequired : ''"
        :class="{'border-red' : errors?.[inputKey]}"
    />
    <span class="text-error">{{ errors?.[inputKey] ? errorMessage : '' }}</span>
  </div>
</template>

<script>
import {Field} from "vee-validate";

export default {
  name: "ReusableField",
  components: {Field},
  props: {
    inputKey: {
      default: ''
    },
    prev_val: {
      default: ''
    },
    labelText: {
      default:''
    },
    errors: {
      default: {}
    }
    ,
    errorMessage : {
      default: 'هذا الحقل مطلوب!'
    },
    notRequired: {
      type: Boolean
    }
  },
  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
  }
}
</script>
