<template>
<div>
  <el-card class="custom-card border">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="بيانات المستخدم" name="userDetails">
        <div class="row my-3">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-6 mt-3 mt-lg-0">
                <reusable-field inputKey="first_name" label-text="الاسم الأول" @update="handleChange" :prev_val="user.first_name"
                                :errors="errors" error-message="الرجاء إدخال الاسم الأول"
                />
              </div>
              <div class="col-lg-6 mt-3 mt-lg-0">

                <reusable-field inputKey="last_name" label-text="الاسم الأخير" @update="handleChange" :prev_val="user.last_name"
                                :errors="errors" error-message="الرجاء إدخال الاسم الأخير"
                />
              </div>
              <div class="col-lg-6 mt-3">
                <reusable-field inputKey="username" label-text="اسم المستخدم" @update="handleChange" :prev_val="user.username"
                                :errors="errors" error-message="الرجاء إدخال اسم المستخدم"
                />
              </div>
              <div class="col-lg-6 mt-3">

                <reusable-field inputKey="display_name" label-text="الاسم المعروض" @update="handleChange" :prev_val="user.display_name"
                                :errors="errors" error-message="الرجاء إدخال الاسم المعروض"
                />
              </div>

              <div class="col-lg-6 mt-3">
                <reusable-field inputKey="mobile" label-text="رقم المحمول" @update="handleChange" :prev_val="user.mobile"/>
              </div>

              <div class="col-lg-6 mt-3">
                <reusable-field inputKey="email" label-text="البريد الإلكتروني" @update="handleChange" :prev_val="user.email"
                                :errors="errors" error-message="الرجاء إدخال البريد الإلكتروني"
                />
              </div>


              <div class="col-lg-6 mt-3">
                <reusable-field inputKey="address" label-text="العنوان" @update="handleChange" :prev_val="user.address"/>
              </div>

              <div class="col-lg-6 mt-3" v-if="$route.name === 'add-user'">
                <div class="form-group" >
                  <label for="password">كلمة المرور</label>
                  <div class="position-relative password-wrapper">
                    <Field
                        :type="isTextShow ? 'text' : 'password'"
                        name="password"
                        id="password"
                        v-model="user.password"
                        placeholder="كلمة المرور"
                        class="form-control form-control-solid"
                        :rules="isRequired"
                        :class="{'border-red' : errors.password}"
                    />
                    <button class="btn top-0 left-0 position-absolute btn-toggle" type="button"
                            @click="isTextShow = !isTextShow">
                      <i class="far" :class="!isTextShow ? 'fa-eye' : 'fa-eye-slash'"></i>
                    </button>
                  </div>
                  <span class="text-error">{{ errors.password ? 'الرجاء ادخال كلمة المرور' : '' }}</span>
                </div>
              </div>


              <div class="col-lg-6 mt-3">
                <reusable-field inputKey="facebook" label-text="فيسبوك" @update="handleChange" :prev_val="user.facebook"/>
              </div>

              <div class="col-lg-6 mt-3">
                <reusable-field inputKey="twitter" label-text="تويتر" @update="handleChange" :prev_val="user.twitter"/>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label>الصورة</label>
              <label class="placeholder-image mt-0" @click="showUploadCenter=true;isSingleUpload=true">
                <img class="w-100" height="220" v-if="user_image" :src="APP_URL + user_image" alt="">
                <!--          <img src="/media/cloud-img.png" v-else class="img-float" alt="" />-->
              </label>

            </div>

            <div class="form-group">
              <label>نوع المستخدم</label>
              <select class="form-control-solid form-select" :value="user.type_id"
                      @input="e =>$emit('handleUpdate','type_id', e.target.value)">
                <option v-for="type in usersTypes" :key="type.id" :value="type.id">{{ type.description }}</option>
              </select>
            </div>
            <div class="form-group mt-2">
              <label for="description">وصف المستخدم</label>
              <textarea name="description" id="description" rows="3" class="form-control"
                        :value="user.description"
                        @input="e =>$emit('handleUpdate','description', e.target.value)"></textarea>

            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-card>

  <el-dialog   :destroy-on-close=true
               title="مركز الرفع"
               v-model="showUploadCenter"
  >
    <UploadCenter :is-single-upload="isSingleUpload" @save="handleAddPhoto"/>
  </el-dialog>
</div>
</template>

<script>
import {getUserTypes} from "@/services/users";
import {Field} from "vee-validate";
import ReusableField from "@/components/formControl/reusableField";
import UploadCenter from "@/components/uploadCenter";

export default {
  name      : "UserDetails",
  components: {
    Field,
    UploadCenter,
    ReusableField
  },
  props     : {
    errors      : {},
    handleChange: {
      default: () => {}
    },
    user        : {
      default: {
        first_name  : null,
        last_name   : null,
        display_name: null,
        username    : null,
        email       : null,
        address     : null,
        mobile      : null,
        password    : null,
        type_id     : null,
        image       : null,
        description : null,
        role        : [],
        facebook    : null,
        twitter     : null
      }
    }
  },
  data()
  {
    return {
      isTextShow: false,
      activeTab : "userDetails",
      usersTypes: [],
      showUploadCenter: false,
      isSingleUpload: false,
      user_image: this.user?.image || ''
    }
  },
  computed: {
    APP_URL: function () {
      return process.env.VUE_APP_IMAGE_REMOTE_URL
    },
  },
  mounted()
  {
    this.getUsersType();
  },
  methods: {
    isRequired(value)
    {
      return value ? true : 'This field is required';
    },
    getUsersType()
    {
      getUserTypes()
          .then(res => {
            this.usersTypes = res.data
          })
    },

    closeUploadCenter() {
      this.showUploadCenter = false;
      this.isSingleUpload = false;
    },
    handleAddPhoto(photo) {
      if (this.showUploadCenter) {
        this.user_image = photo.url;
        this.$emit('handleUpdate','image', photo.url)
      }
      this.closeUploadCenter();
    },

    handleChangeImage(e)
    {
      let selectedFile = e.target.files;
      if (selectedFile.length !== 0)
      {
        const fileReader  = new FileReader();
        fileReader.onload = (e) => {
          this.user.image = fileReader.result;
          this.$emit('handleUpdate', 'image', fileReader.result)

        };
        fileReader.readAsDataURL(selectedFile[0]);
      }
    }
  }
}
</script>

<style lang="scss">

.placeholder-image {
  min-height: 230px;

  img {
    object-fit: cover;
    object-position: center;
  }
}

.password-wrapper {
  input {
    padding-inline-end: 2.5rem;
  }

  .btn-toggle {
    box-shadow: none;

    :hover {
      color: #8c0ac4;
    }
  }
}
</style>
